const React = require('react');
const { ErrorScreen } = require('@andes/technical-error');
const SearchWebLarge = require('@andes/illustrations/SearchWebLarge');
const { injectI18n } = require('nordic/i18n');

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    if (window.newrelic) {
      window.newrelic.noticeError(error, { errorInfo });
    }
  }

  render() {
    const { hasError } = this.state;
    const { children, i18n } = this.props;

    if (hasError) {
      return (
        <ErrorScreen
          action={{
            text: i18n.gettext('Ir a la página principal'),
            href: '/',
          }}
          asset={<SearchWebLarge />}
          className="error-boundary__container"
          title={i18n.gettext('Oops, algo salió mal')}
        />
      );
    }
    return children;
  }
}

module.exports = injectI18n(ErrorBoundary);
