/* eslint-disable no-eval */
const React = require('react');
const PropTypes = require('prop-types');
const Hotjar = require('nordic/hotjar');
const { traceErrorWebView } = require('@utils/webviews');

/**
 * Parse recording tags.
 * Adds some default tags and prefix them if tagPrefix is present.
 * @param {array} customTags
 * @param {string} tagPrefix
 */
const parseTags = (customTags = [], tagPrefix = '') => {
  const tags = Array.isArray(customTags) ? customTags : [];

  return JSON.stringify(
    // make sure we do not have duplicated tags
    [...new Set(tags)]
      // prepend tag prefix
      .map(tag => tagPrefix + tag),
  );
};

const parseTriggers = customTriggers => {
  const triggers = Array.isArray(customTriggers) ? customTriggers : [];
  return [...new Set(triggers)]; // make sure we do not have duplicated triggers
};

/**
 * Returns the Hotjar JS code.
 * @param {object} options
 */
const getHotjarCode = (options, debug, id) => {
  const triggers = parseTriggers(options.triggers);
  /* istanbul ignore next */
  const defaultId = 783944;
  return `
    (function(h,o,t,j,a,r){
      h.hj= h.hj || function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:${id || defaultId},hjdebug:${debug},hjsv:5};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');
    hj('tagRecording', ${parseTags(options.tags, options.tagPrefix)});
    ${triggers.length ? triggers.map(trigger => `hj('trigger', '${trigger}');`).join('\n\t') : ''}
    `;
};

const showHotjar = (d2id, from, to) => {
  // eslint-disable-next-line no-param-reassign
  const identifier = d2id.split('').reduce((sum, l) => (sum += l.charCodeAt(0)), 0) % 100;
  return identifier >= from && identifier < to;
};

const HotjarWrapper = ({
  traffic = {},
  tags = [],
  tagPrefix,
  triggers,
  debug,
  d2id = '',
  isDeferred,
  id = '',
  isHub,
}) => {
  if (debug) {
    traffic.from = 0;
    traffic.to = 100;
  }

  const options = {
    tags,
    tagPrefix,
    triggers,
    traffic,
    id,
  };

  if ((traffic.from === 0 && traffic.to === 0) || !showHotjar(d2id, traffic.from, traffic.to)) {
    return null;
  }

  if (!isDeferred && !isHub) {
    return <Hotjar traffic={traffic} tags={tags} tagPrefix={tagPrefix} triggers={triggers} id={id} />;
  }

  if (!isDeferred && isHub) {
    return <Hotjar traffic={traffic} tags={tags} tagPrefix={tagPrefix} triggers={triggers} />;
  }

  if (typeof window === 'object') {
    window.meli_hotjar = () => {
      try {
        /* istanbul ignore next */
        eval(getHotjarCode(options, debug, id));
      } catch (err) /* istanbul ignore next */ {
        const error = {
          msg: err.message || err.stack || 'Error while trying to load hotjar script',
        };
        traceErrorWebView(error);
      }
      delete window.meli_hotjar;
    };
  }

  return null;
};

HotjarWrapper.defaultProps = {
  tagPrefix: '',
  tags: [],
  triggers: [],
  debug: false,
  isDeferred: false,
  isHub: false,
};

HotjarWrapper.propTypes = {
  tagPrefix: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  triggers: PropTypes.arrayOf(PropTypes.string),
  traffic: PropTypes.shape({ from: PropTypes.number, to: PropTypes.number }).isRequired,
  debug: PropTypes.bool,
  d2id: PropTypes.string.isRequired,
  isDeferred: PropTypes.bool,
  isHub: PropTypes.bool,
};

module.exports = HotjarWrapper;
