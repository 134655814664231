const React = require('react');
const { Skeleton, SkeletonFlex } = require('@webview/react-skeletor');

const styles = {
  backgroundWhite: {
    backgroundColor: '#fff',
    padding: '54px 0 54px 0',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 2px 2px rgba(0,0,0,0.15)',
    width: '49%',
  },
};

const SkeletonCard = () => (
  <SkeletonFlex style={styles.card} justify="center" type="column">
    <Skeleton className="skeleton-card_item" />
    <Skeleton className="skeleton-card_item" />
  </SkeletonFlex>
);

const SkeletonComp = () => {
  return (
    <section className="skeleton-component">
      <SkeletonFlex justify="center" padding="24px 24px 14px 24px" type="column">
        <Skeleton width={194} height={14} margin="32px 0 10px 0" />
        <Skeleton width={124} height={8} margin="0 0 50px 0" />
        <SkeletonFlex justify="center" align="center" type="row">
          <Skeleton width={9} height={9} type="circle" margin="0 5px 0 0" />
          <Skeleton width={6} height={6} type="circle" margin="0 5px 0 0" />
          <Skeleton width={6} height={6} type="circle" />
        </SkeletonFlex>
      </SkeletonFlex>
      <SkeletonFlex style={styles.backgroundWhite} align="center" type="column">
        <SkeletonFlex align="center" type="column">
          <Skeleton width={222} height={14} margin="0 0 12px 0" />
          <Skeleton width={250} height={8} margin="0 0 48px 0" />
          <Skeleton width={72} height={10} />
        </SkeletonFlex>
        <SkeletonFlex align="center" margin="64px 0 33px 0" type="column">
          <Skeleton width={43} height={43} type="circle" />
          <Skeleton width={258} height={14} margin="24px 0 12px 0" />
          <Skeleton width={182} height={8} />
        </SkeletonFlex>
      </SkeletonFlex>
      <SkeletonFlex justify="center" padding="28px 8px 14px 8px" type="column">
        <SkeletonFlex justify="space-between" type="row" margin="0 0 12px 0">
          <Skeleton width={72} height={10} />
          <Skeleton width={30} height={5} />
        </SkeletonFlex>
        <SkeletonFlex justify="space-between" type="row">
          <SkeletonCard />
          <SkeletonCard />
        </SkeletonFlex>
      </SkeletonFlex>
    </section>
  );
};

module.exports = SkeletonComp;
