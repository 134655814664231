const React = require('react');
const { AndesProvider } = require('@andes/context');
const I18n = require('nordic/i18n');
const { I18nProvider } = require('nordic/i18n');

const WithI18nAndesProvider = ({ children, preload }) => {
  const { locale, translations } = preload;
  const i18n = new I18n({ translations });
  i18n.locale = locale;

  return (
    <I18nProvider i18n={i18n}>
      <AndesProvider locale={locale}>{children}</AndesProvider>
    </I18nProvider>
  );
};

module.exports = WithI18nAndesProvider;
