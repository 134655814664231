const React = require('react');
const { Page: NordicPage } = require('nordic/page');
const { Script } = require('nordic/script');

const Page = ({ children, ...props }) => (
  <NordicPage {...props}>
    <Script id="newrelic-handler">
      {`
        if (typeof newrelic !== 'undefined') {
          const ignoredMessages = ["Script error."];

          // return true to ignore error
          newrelic.setErrorHandler(function(err) {
            return ignoredMessages.includes(err.message);
          });
        }
      `}
    </Script>

    {children}
  </NordicPage>
);

module.exports = Page;
