require('core-js/stable');
require('regenerator-runtime/runtime');

const { formatLandingNameByType, retrieveCIDItemsIds, motorsIds, realEstateIds } = require('../utils');

class MeliGA {
  /**
   * Generate the path dynamically for MeliGA according to the vertical and the business unit
   * @param {object} dataLanding
   * @param {string} landingType
   * @returns {string}
   */
  static generateDynamicPath(dataLanding, landingType) {
    const { site, metrics, folder, type = null, url = null } = dataLanding;
    const landingName = site.analytics.toUpperCase();
    const businessUnit = (metrics && metrics.bu) || 'ALL';
    const landingVertical = (metrics && metrics.vertical) || 'DEFAULT';

    return `LANDING/${businessUnit}/${landingVertical}${formatLandingNameByType(
      landingName,
      type || landingType,
      url,
      folder,
    )}`;
  }

  /**
   * Send all tracks for categories (melidata and analytics)
   * @param {object} dataLanding
   * @returns {void}
   */
  static sendCategoriesTracks(dataLanding, dealPrintId) {
    const { metrics, name } = dataLanding;
    const categoryId = metrics.category.category_id;

    if (typeof meli_ga === 'function') {
      if (motorsIds.includes(categoryId)) meli_ga('set', 'dimension8', 'MOTORS');
      if (realEstateIds.includes(categoryId)) meli_ga('set', 'dimension8', 'REAL_ESTATE');
      meli_ga('set', 'dimension3', JSON.stringify({ [categoryId]: name }).replace(/"/g, "'"));
      meli_ga('set', 'dimension113', dealPrintId);
      meli_ga('send', { hitType: 'pageview' });
    }
  }

  /**
   * Send all tracks for melicoins
   * @returns {void}
   */
  static sendMeliCoinsTracks() {
    if (typeof meli_ga === 'function') {
      meli_ga('send', { hitType: 'pageview' });
    }
  }

  /**
   * Send all tracks for MercadoShops
   * @param {object} dataLanding
   * @returns {void}
   */
  static sendMercadoShopsTracks(_dataLanding, _dealPrintId, _landingType) {
    if (typeof meli_ga === 'function') {
      meli_ga('send', { hitType: 'pageview' });
    }
  }

  /**
   * Send all tracks for deals (melidata and analytics) append extra content for CID
   * @param {object} dataLanding
   * @param {string} dealPrintId
   * @param {string} landingType
   * @returns {void}
   */
  static async sendDealsTracks(dataLanding, dealPrintId, _landingType, siteId) {
    const { components, isTakeover, metrics, site, deal_id = '' } = dataLanding;
    const merchEngineMS = components.find(component => component.component_name === 'MainSliderPlacement');
    const carrouselItemsIds = components.filter(component => component.component_name === 'CarouselDynamic');
    const sendIds = carrouselItemsIds.length
      ? await retrieveCIDItemsIds(dataLanding.clientSide, carrouselItemsIds, siteId)
      : 'NONE';
    const freshIds =
      merchEngineMS?.items?.map(m => m.data.event_data?.content_id).filter(i => i?.includes('FRESH_HIBRIDO_')) ?? [];
    const freshInitiative = (!!freshIds.length && freshIds.shift().split('FRESH_HIBRIDO_')[1]) || '';

    const contextLanding = isTakeover
      ? 'takeover-landing'
      : `normal-landing${freshInitiative ? `-${freshInitiative}` : ''}`;

    const isBrandStore = metrics?.vertical === 'BRAND_STORES';
    const brandStoreId = site?.analytics.toUpperCase() || 'NONE';

    if (typeof meli_ga === 'function') {
      if (isBrandStore) meli_ga('set', 'dimension9', brandStoreId);
      if (deal_id) meli_ga('set', 'dimension48', deal_id);
      meli_ga('set', 'dimension49', sendIds.toString());
      meli_ga('set', 'dimension117', contextLanding);
      meli_ga('set', 'dimension113', dealPrintId);
      meli_ga('send', { hitType: 'pageview' });
    }
  }
}

module.exports = MeliGA;
