require('@pages/hub/styles.scss');

const React = require('react');
const { View } = require('@pages/hub/view');
const hydrate = require('nordic/hydrate');
const { loadableReady } = require('nordic/lazy');
const WithI18nAndesProvider = require('@components/WithI18nAndesProvider');

const props = window.__PRELOADED_STATE__;

loadableReady(() => {
  hydrate(
    <WithI18nAndesProvider preload={props}>
      <View {...props} />
    </WithI18nAndesProvider>,
    document.getElementById('root-app'),
  );
});
