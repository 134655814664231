// eslint-disable-next-line no-undef

const isDev = process.env.NODE_ENV === 'development';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

module.exports = function displayName(HigherOrder, Wrapped) {
  const higherOrderName = getDisplayName(HigherOrder);
  const wrappedName = getDisplayName(Wrapped);

  if (isDev && wrappedName.indexOf(`${higherOrderName}(`) > -1) {
    throw new Error(`Wrapping with '${higherOrderName}' already wrapped component '${wrappedName}'.`);
  }

  return `${higherOrderName}(${wrappedName})`;
};
