module.exports = {
  seoDescription: (name, siteId) => {
    const descriptionPt = `Encontre o que esta buscando em ${name}. Todo o que você necessita está em Mercado Livre.`;
    return siteId === 'MLB'
      ? descriptionPt
      : `Encontrá lo que buscás en ${name}. Todo lo que necesitas lo conseguís en un solo lugar, en Mercado Libre.`;
  },
  seoTitleTemplate: (name, siteId, siteName) => {
    return `${name} ${siteId === 'MLB' ? 'no Mercado Livre' : 'en Mercado Libre'} ${siteName}`;
  },
  getParams: ({ link, source, label, uuid, webView, categoryId }) => {
    const showDealId = link.deal_id
      ? `#DEAL_ID=${link.deal_id}&S=${source}&V=${link.position}&T=${link.component}&L=${encodeURI(label)}`
      : `#S=${source}&V=${link.position}&T=${link.component}&L=${encodeURI(label)}`;
    const urlHasFragment = link && link.url && /#/.test(link.url);
    const fragment = webView && link?.url ? `#${link.url.split('#').pop()}` : '';
    if (categoryId) {
      return `#CATEGORY_ID=${categoryId}&S=${source}`;
    }
    if (uuid && !link.categoryId) {
      if (link.deal_id) {
        if (urlHasFragment) {
          return `${fragment}&DEAL_ID=${link.deal_id}&S=${source}&V=${link.position}&T=${link.component}&L=${encodeURI(
            label,
          )}&deal_print_id=${uuid}`;
        }

        return `${showDealId}&deal_print_id=${uuid}`;
      }

      if (urlHasFragment) {
        return `${fragment}&deal_print_id=${uuid}`;
      }

      return `#deal_print_id=${uuid}`;
    }

    return `${showDealId}`;
  },
  toCamelCase: /* istanbul ignore next */ word => {
    return (
      word
        .replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''))
        .substr(0, 1)
        .toLowerCase() + word.substr(1)
    );
  },
  hasImportError: (component_name, componentListSpecialImport) => {
    return componentListSpecialImport.includes(component_name);
  },
  getDeeplink: req => {
    const url = `https://${req.platform.domain}${req.originalUrl}`;
    return `meli://webview/?url=${encodeURIComponent(url)}`;
  },
  buildUrl: (originalUrl, host) => {
    const [splittedPath] = originalUrl.split('?');
    const path = splittedPath.toLowerCase();
    return `${host}${path}`;
  },
};
