const React = require('react');
const PropTypes = require('prop-types');

const OpenGraph = ({ title, ogImage, description, ogUrl, ogLocale, ogSiteName }) => {
  return (
    <>
      <meta property="og:title" content={title} data-head-react="true" />
      <meta property="og:description" content={description} data-head-react="true" />
      <meta property="og:image" content={ogImage} data-head-react="true" />
      <meta property="og:url" content={ogUrl} data-head-react="true" />
      <meta property="og:type" content="website" data-head-react="true" />
      <meta property="og:locale" content={ogLocale} data-head-react="true" />
      <meta
        property="og:site_name"
        content={ogSiteName === 'MLB' ? 'Mercado Livre' : 'Mercado Libre'}
        data-head-react="true"
      />
    </>
  );
};

OpenGraph.propTypes = {
  title: PropTypes.string.isRequired,
  ogImage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ogUrl: PropTypes.string.isRequired,
  ogLocale: PropTypes.string.isRequired,
  ogSiteName: PropTypes.string.isRequired,
};

module.exports = OpenGraph;
