const restclient = require('nordic/restclient')({
  baseURL: '/ofertas/splinter',
});
/**
 * Fetch data for the landing with retry policy
 * @param {string} url
 * @param {object} options
 * @param {number} retryCount
 * @returns {Promise}
 */
const fetchDataForWebView = (url, options = {}, retryCount = 0) => {
  return restclient
    .get(`/webview${url}`, options)
    .then(({ data }) => {
      const { dataLanding, trackingData } = data;
      return {
        dataLanding,
        trackingData,
        error: null,
      };
    })
    .catch(error => {
      if (retryCount < 2) {
        return fetchDataForWebView(url, options, retryCount + 1);
      }
      return { error: error.response.data || error.response };
    });
};

/**
 * Open an internal deeplink for WebViews
 * @param {string} deeplink
 * @returns {void}
 */
/* istanbul ignore next */
const openDeepLinkWebView = deeplink => {
  if (typeof window !== 'undefined' && window.MobileWebKit) {
    MobileWebKit.navigation
      .push(deeplink, {
        replace: true,
      })
      .catch(err => {
        if (window.newrelic) {
          window.newrelic.noticeError(err);
        }
      });
  }
};

/**
 * Trace error for webviews fetch
 * @param {object} error
 * @returns {void}
 */
const traceErrorWebView = error => {
  if (window.newrelic) {
    window.newrelic.noticeError(new Error(error.msg), { cookies: document.cookie, uri: window.location.href });
  }
};

module.exports = {
  fetchDataForWebView,
  openDeepLinkWebView,
  traceErrorWebView,
};
